<template>
  <div :style="`background: ${bg}`" class="wrapper layout-wrapper h-100">
    <ClientOnly>
      <nav-bar class="fixed-mobile" />
    </ClientOnly>
    <div id="main-content" class="w-100">
      <div class="content">
        <slot class="router" />
        <ClientOnly>
          <BottomFooter v-if="!isShortHeight && !keyboardOpen" :style="`background: ${bg}`" class="footer" />
        </ClientOnly>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import NavBar from '@/shared/components/nav-bar/NavBar.vue';
import BottomFooter from '@/shared/components/footer.vue';
import { mainStore } from '../pinia-store/main';

const { isShortHeight } = useWindowSize();
const { keyboardOpen } = mainStore();
const { bg } = useCharPageColors();

useCanonicalUrl();
</script>

<style scoped lang="sass">
.force-full-height

#main-content
  height: calc(var(--doc-height) - var(--default-nav-bar-height) - var(--safe-ios-margin) - var(--safe-ios-margin)/3)
  flex-grow: 1
  flex-shrink: 0
  overflow-y: auto
  overflow-x: hidden
  .content
    width: 100%
    height: 100%
    ::v-deep
      .ion-page.page
        min-height: 100%
        overflow: visible
        margin: auto
        position: relative !important
        max-width: 690px
</style>
